import React from 'react'
import { Form, withFormik, Field, ErrorMessage } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import * as Yup from 'yup'
import Button from './button'
import { recaptcha_key } from '../data/config'

const ContactForm = ({
  setFieldValue,
  isSubmitting,
  values,
  errors,
  touched,
}) => (
  <Form
    name="contact-form"
    method="post"
    data-netlify="true"
    data-netlify-recaptcha="true"
    data-netlify-honeypot="bot-field"
    css={`
      flex-grow: 1;
    `}
  >
    <h4>Contact Us</h4>
    <div css={` position: relative; margin-bottom: 1rem; `}>
      <Field
        type="text"
        name="name"
        component="input"
        aria-label="name"
        placeholder="Full name*"
        error={touched.name && errors.name}
      />
      <ErrorMessage
        component={'span'}
        name="name"
        css={`
          color: #ff4136;
        `}
      />
    </div>
    <div css={` position: relative; margin-bottom: 1rem; `}>
      <Field
        id="email"
        aria-label="email"
        component="input"
        type="email"
        name="email"
        placeholder="Email*"
        error={touched.email && errors.email}
      />
      <ErrorMessage
        component={'span'}
        name="email"
        css={`
          color: #ff4136;
        `}
      />
    </div>
    <div css={` position: relative; margin-bottom: 1rem; `}>
      <Field
        id="company"
        aria-label="company"
        component="input"
        name="company"
        placeholder="Company"
      />
    </div>
    <div css={` position: relative; margin-bottom: 1rem; `}>
      <Field
        as="textarea"
        aria-label="message"
        id="message"
        rows="5"
        type="text"
        name="message"
        placeholder="Message*"
        error={touched.message && errors.message}
        css={`
          max-width: fit-content;
          min-width: 100%;
        `}
      />
      <ErrorMessage
        component={'span'}
        name="message"
        css={`
          color: #ff4136;
        `}
      />
    </div>
    {values.name && values.email && values.message && (
      <div
        css={`
          position: relative;
          margin-bottom: 1rem;
        `}
      >
        <ReCAPTCHA
          sitekey={recaptcha_key}
          name="recaptcha"
          onChange={value => setFieldValue('recaptcha', value)}
        />
        <ErrorMessage
          component={'span'}
          name="recaptcha"
          css={`
            color: #ff4136;
          `}
        />
      </div>
    )}
    {values.success && (
      <div
        css={`
          position: relative;
          margin-bottom: 1rem;
          max-width: 500;
        `}
      >
        <div>
          <h4
            css={`
              font-weight: normal;
            `}
          >
            Your message has been successfully sent, typically you will get a
            response within 1-2 days.
          </h4>
        </div>
      </div>
    )}
    <div>
      <Button type="submit" disabled={isSubmitting}>
        Submit
      </Button>
    </div>
  </Form>
)

export default withFormik({
  validateOnBlur: false,
  mapPropsToValues: () => ({
    name: '',
    email: '',
    company: '',
    message: '',
    recaptcha: '',
    success: false,
  }),
  validationSchema: () =>
    Yup.object().shape({
      name: Yup.string().required('Full name field is required'),
      email: Yup.string()
        .email('Invalid email')
        .required('Email field is required'),
      company: Yup.string(),
      message: Yup.string().required('Message field is required'),
      recaptcha: Yup.string().required('Robots are not welcome yet!'),
    }),
  handleSubmit: async (
    { name, email, company, message, recaptcha },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const encode = data => {
        return Object.keys(data)
          .map(
            key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
          )
          .join('&')
      }
      await fetch('/contact-form?no-cache=1', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'contact-form',
          name,
          email,
          company,
          message,
          'g-recaptcha-response': recaptcha,
        }),
      })
      await setSubmitting(false)
      await setFieldValue('success', true)
      setTimeout(() => resetForm(), 6000)
    } catch (err) {
      setSubmitting(false)
      setFieldValue('success', false)
      alert('Something went wrong, please try again!') // eslint-disable-line
    }
  },
})(ContactForm)
